import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Facebook, Twitter } from './'

const SEO = ({ title, description, pathname }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          name,
          defaultTitle,
          defaultDescription,
          lang,
          siteUrl,
        }
      }
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || '/'}`,
      };

      let schemaOrgJSONLD = [
        {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          '@id': siteUrl,
          url: siteUrl,
          name: defaultTitle,
          alternateName: name || '',
        },
      ];

      return (
        <>
          <Helmet title={seo.title}>
            <html lang={lang} />
            <meta name="description" content={seo.description} />
            <meta name="apple-mobile-web-app-title" content={name} />
            <meta name="application-name" content={name} />
            <meta name="generator" content={name} />
            <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
          </Helmet>
          <Facebook
            title={seo.title}
            desc={seo.description}
          />
          <Twitter
            title={seo.title}
            desc={seo.description}
          />
        </>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  pathname: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        name
        defaultTitle: title
        defaultDescription: description
        lang
        siteUrl
      }
    }
  }
`