import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import theme from '../../config/theme'

const Footer = styled.footer`
  padding: 0 20px;
  background-color: #ffffff;
  @media (min-width: ${theme.breakpoints.desktop}) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const Copy = styled.h3`
  border-top: 2px solid ${theme.colors.primary};
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  padding: 20px 0;
`

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              footerCopyright
            }
          }
        }
      `}
      render={data => (
        <Footer>
          <Copy>&copy; {data.site.siteMetadata.footerCopyright}</Copy>
        </Footer>
      )}
    />
  )
}