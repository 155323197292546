import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'

import { Header, Footer } from '../components'
import theme from '../../config/theme'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${theme.colors.background};
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    color: ${theme.colors.bodyText};

    ::after {
      position: fixed;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: -1;
    }
  }

  a {
    font-weight: bold;
  }

  img {
    margin: 0;
    padding: 0;
  }
`

const Layout = styled.div`
  @media (min-width: ${theme.breakpoints.desktop}) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
`

const Wrapper = styled.div`
  @media (min-width: ${theme.breakpoints.desktop}) {
    width: 960px;
  }
`

const Main = styled.main`
  background-color: #ffffff;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ({ children }) => (
  <Layout>
    <GlobalStyle />
    <Wrapper>
      <Header />
      <Main>
        {children}
      </Main>
      <Footer />
    </Wrapper>
  </Layout>
)