import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import theme from '../../config/theme'

const Header = styled.header`
  display: flex;
  height: 80px;
  align-items: center;
  background-color: ${theme.colors.primary};
  @media (min-width: ${theme.breakpoints.desktop}) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`

const Title = styled.h1`
  color: ${theme.colors.headerText};
  margin: 0 auto;
  text-align: center;
  @media (min-width: ${theme.breakpoints.tablet}) {
    padding: 0 0 0 30px;
    margin: 0 auto 0 0;
    text-align: left;
  }
`

const Subtitle = styled.h2`
  color: ${theme.colors.headerText};
  margin: 0 auto;
  font-weight: 400;
  font-size: 1.5rem;
  display: none;
  @media (min-width: ${theme.breakpoints.tablet}) {
    display: block;
    padding: 0 30px 0 0;
    margin: 0 0 0 auto;
    text-align: right;
  }
`

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              headerTitle
              headerSubTitle
            }
          }
        }
      `}
      render={data => (
        <Header>
          <Title>{data.site.siteMetadata.headerTitle}</Title>
          <Subtitle>{data.site.siteMetadata.headerSubTitle}</Subtitle>
        </Header>
      )}
    />
  )
}