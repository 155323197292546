import React from 'react'
import styled from 'styled-components'

import { Layout, SEO } from '../components'
import theme from '../../config/theme'

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  @media (min-width: ${theme.breakpoints.tablet}) {

  }
  @media (min-width: ${theme.breakpoints.desktop}) {

  }
`

const Ad = styled.div`
  margin: 10px;
  background-color: #ccc;
  width: 300px;
  height: 250px;
`

const AdFirst = styled(Ad)`
  @media (min-width: ${theme.breakpoints.desktop}) {
    order: 1;
  }
`

const AdSecond = styled(Ad)`
  @media (min-width: ${theme.breakpoints.desktop}) {
    order: 3;
  }
`

const Main = styled.div`
  text-align: justify;
  margin: 10px;
  display: none;
  @media (min-width: ${theme.breakpoints.tablet}) {
    width: 728px;
    display: block;
  }
  @media (min-width: ${theme.breakpoints.desktop}) {
    width: 600px;
    order: 2;
  }
`

const Content = styled.div`
  text-align: justify;
  margin: 10px;
  @media (min-width: ${theme.breakpoints.tablet}) {
    width: 728px;
  }
  @media (min-width: ${theme.breakpoints.desktop}) {
    width: 600px;
    order: 4;
  }
`

export default () => (
  <Layout>
    <SEO />
    <Row>
      <Main id="main" />
      <Content>
        <p><strong>Nota informativa:</strong></p>
        <p>Esta página web no ofrece la venta directa de <strong>billetes de vuelos</strong> y no tiene ninguna relación con ninguna agencia de viajes online.</p>
        <p>Usted puede buscar <strong>vuelos baratos</strong> en otras páginas web de agencias de viajes online o puede utilizar libremente el multi buscador <a href="https://www.terminal.es">Terminal.es</a> para poder encontrar las mejores ofertas de <strong>vuelos baratos y billetes baratos</strong> a miles de destinos a los mejores precios.</p>
        <p>También puede buscar las mejores <strong>ofertas de alojamiento</strong> en <a href="https://www.alojamiento.es">Alojamiento.es</a></p>
      </Content>
      <AdFirst>
        <div id="div-gpt-ad-1336342304984-0" />
      </AdFirst>
      <AdSecond>
        <div id="div-gpt-ad-1336342304984-1" />
      </AdSecond>
    </Row>
  </Layout>
)