const theme = {
  colors: {
    primary: '#0d53cc',
    background: '#444',
    bodyText: '#444',
    headerText: '#fff',
  },
  breakpoints: {
    mobile: '425px',
    tablet: '768px',
    desktop: '1024px',
  },
}

export default theme